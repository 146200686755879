export const config = {
  title: 'AI Academic Writer',
  Writetab: "Write Academic",

  Topic : 'Topic/description',
  Topicplaceholder:'Academic Writing on The Impact of Artificial Intelligence on Society',
  selectType : 'Select Type',
  Keypointsplaceholder:'',
  SupportiveInput: 'Select Citation',
  SupportiveInput2: 'Select Tone',
  //color: '#FF3A12',
 
 color:'#E670CC',
  getacceseline1: ' AI Advanced Academic Writing Tool',
  getacceseline2: ' Unlimited Access to Academic Writing & Research',
  getacceseline3: ' Extensive Research Database',
  getacceseline4: ' No Ads',




  //color used across web 
  primaryColor: '#E670CC',
  tabsColor: '#E670CC',


  //images used accross web
  logo: require('./assets/vector.svg').default,
  buyP: require('./assets/Group 1437252828.svg').default,
  home: require('./assets/filled.svg').default,
  homelp: require('./assets/filled leftp.svg').default,
  privacy: require('./assets/description.svg').default,
  privacylp: require('./assets/description leftp.svg').default,
  generate: require('./assets/button.svg').default,
  hamburger: require('./assets/USSSA - Icons_Hamburger Menu.svg').default,
  crown: require('./assets/crown 1.svg').default,
  frame: require('./assets/fram.svg').default,
  proicon: require('./assets/pro.svg').default,
  upgradetopro: require('./assets/upgradetopro.png')  ,
  righticon: require('./assets/right.svg').default,
  Backbutton: require('./assets/arrow-left.svg').default,  
  Buybutton: require('./assets/arrow long right.svg').default,
  book: require('./assets/book.svg').default, 




  //api endpoitns accross web
 // apiEndpoint: 'http://localhost/projectA/api',

 baseURL:"https://aiacademicswriter.com//Backend",

//baseURL:"http://localhost/",


  "FAQtitle": "How to Use Ai Academic Writer?",
  "points": [
    {
      "step": "Provide Detailed Instructions:",
      "details": [
        "Ensure your instructions are thorough and use all selectors correctly.",
        "Add: Specify a topic related to academic creation (keep it concise).",
        "Select: Choose the type, citation style, tone, and language."
      ]
    },
    {
      "step": "Generate Content:",
      "details": [
        "Click the “Generate” button and wait for the results.",
        "The average generation time is about 2-4 seconds."
      ]
    },
    {
      "step": "Copy and Share:",
      "details": [
        "You can copy and share the generated academic content.",
        "In some cases, you might be prompted to save your file."
      ]
    },
    {
      "step": "Subscription for Best Results:",
      "details": [
        "Purchase a subscription for additional features and more attempts."
      ]
    }
  ],



  //privacy page 
    "privacyTitle": "Privacy Policy for AI Academic Writer",
    "privacyEffectiveDate": "13-7-2023",
    "privacyPoints": [
      {
        "heading": "1. Information We Collect",
        "subsections": [
          {
            "heading": "1.1 Personal Information",
            "content": [
              "We may collect personal information that you provide directly to us when using the App. This includes:",
              "- Your name, email address, and other contact information when you sign up or contact us for support.",
              "- Story content and other user-generated content you provide within the App.",
              "- Communication and interaction details when you communicate with other users or us through the App."
            ]
          },
          {
            "heading": "1.2 Usage Information",
            "content": [
              "When you use the App, we may automatically collect certain information about your device and usage patterns, including:",
              "- Device information (such as model, operating system version, unique device identifiers).",
              "- Log information (such as IP address, access dates and times, and referring/exit pages).",
              "- App usage data, including the features you use, the pages you visit, and the actions you take."
            ]
          }
        ]
      },
      {
        "heading": "2. Use of Information",
        "subsections": [
          {
            "heading": "2.1 Provide and Improve the App",
            "content": [
              "We use the collected information to provide, maintain, and improve the functionality and performance of the App. This includes:",
              "- Personalizing your experience and providing you with tailored content.",
              "- Enhancing the App's security and detecting, preventing, and responding to potential fraud or security issues."
            ]
          }
        ]
      },
      {
        "heading": "3. Sharing Your Information",
        "content": [
          "- We do not share your personal information with third parties, except as required by law or with your explicit consent."
        ]
      }
    ],




    "termandcondition": "Terms of Use Policy for Ai Academic Writer ",
  "termandconditionEffectiveDate": "16-12-2024",
  "termandconditionPoints": [
    {
      "heading": "1. Introduction",
      "content": [
        "Welcome to our Ai Academic Writer ! These terms of use outline the rules and regulations for the use of our mobile applications powered by AI technology. By accessing or using our apps, you agree to comply with these terms and conditions. If you do not agree with any part of these terms, you may not access or use our apps."
      ]
    },
    {
      "heading": "2. License",
      "content": [
        "Our apps are licensed to you on a limited, non-exclusive, non-transferable, and revocable basis. You may use our apps solely for your personal, non-commercial purposes and in accordance with these terms."
      ]
    },
    {
      "heading": "3. Restrictions",
      "subsections": [
        {
          "heading": "3.1 General Restrictions",
          "content": [
            "When using our apps, you must not:",
            "- Violate any applicable laws or regulations.",
            "- Infringe upon the rights of others.",
            "- Introduce any malicious code or software.",
            "- Attempt to gain unauthorized access to our apps or any related systems or networks.",
            "- Interfere with the proper functioning of our apps."
          ]
        }
      ]
    },
    {
      "heading": "4. Intellectual Property",
      "content": [
        "All intellectual property rights in our apps, including but not limited to copyrights, trademarks, and patents, are owned by us or our licensors. You may not reproduce, modify, distribute, or otherwise use any of our intellectual property without our prior written consent."
      ]
    },
    {
      "heading": "5. Privacy",
      "content": [
        "We respect your privacy and are committed to protecting your personal information. Our Privacy Policy explains how we collect, use, and disclose your information when you use our apps. By using our apps, you consent to the collection, use, and disclosure of your information as described in our Privacy Policy."
      ]
    },
    {
      "heading": "6. Disclaimer",
      "content": [
        "Our apps are provided on an ‘as is’ and ‘as available’ basis. We make no warranties or representations of any kind, whether expressed or implied, regarding the operation or availability of our apps, or the accuracy, reliability, or completeness of any information provided through our apps."
      ]
    },
    {
      "heading": "7. Limitation of Liability",
      "content": [
        "In no event shall we be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with the use of our apps. You agree to indemnify and hold us harmless from any claims, losses, or damages, including legal fees, arising out of your use of our apps or any violation of these terms."
      ]
    },
    {
      "heading": "8. Changes to Terms",
      "content": [
        "We reserve the right to modify or update these terms of use at any time. Any changes will be effective immediately upon posting the updated terms on our website. It is your responsibility to review these terms periodically for any changes."
      ]
    },
    {
      "heading": "9. Contact Us",
      "content": [
        "If you have any questions or concerns about these terms of use, please contact us at speedapps1010@gmail.com."
      ]
    }
  ],
  "refundTitle": "Refund & Cancellation Policy",
  "refundEffectiveDate": "15th August 2024",
  "refundPoints": [
    {
      "heading": "1. Refund Policy",
      "subsections": [
        {
          "heading": "1.1 Subscription Products",
          "content": [
            "Ai Academic Writer provides digital products and services in the form of subscriptions. Due to the nature of our offerings, we maintain the following refund guidelines:",
            "- **Trial Periods**: If your subscription plan includes a trial period, you may cancel at any time during the trial to avoid charges. Refunds will not be applicable if the subscription is canceled after the trial period ends and the payment has been processed.",
            "- **30-Day Money-Back Guarantee**: We offer a 30-day money-back guarantee for first-time users. If you are not satisfied with our service, you may request a refund within 30 days of your initial purchase. After 30 days, no refunds will be issued.",
            "- **Refund Eligibility**: To be eligible for a refund, your request must meet the following conditions:",
            "  - The request is made within 30 days of the initial purchase.",
            "  - You must provide a valid reason for the refund request, such as technical issues with the software that cannot be resolved.",
            "- **Non-Refundable Subscriptions**: Subscription renewals are non-refundable. You can cancel your subscription at any time to prevent future charges, but refunds for renewal payments will not be issued.",
            "- **Exclusions**: Refunds are not applicable for:",
            "  - Any product purchased with a promotional discount or code.",
            "  - Multi-user or team licenses."
          ]
        },
        {
          "heading": "1.2 Process for Refund Requests",
          "content": [
            "To request a refund, please contact our customer support team at [support@aispeedapps.com] with your order details and the reason for your request. Refunds will be processed within 7-10 business days after approval. Funds will be credited back to the original payment method."
          ]
        }
      ]
    },
    {
      "heading": "2. Cancellation Policy",
      "subsections": [
        {
          "heading": "2.1 Canceling Subscriptions",
          "content": [
            "You can cancel your subscription at any time by logging into your Ai Academic Writer account and navigating to the 'Subscriptions' section. Simply follow the cancellation instructions provided.",
            "- **Immediate Effect**: Once canceled, your subscription will not be renewed. You will continue to have access to the software until the end of your current billing cycle. After that period, your access to the software will be revoked.",
            "- **Future Billing**: If you cancel your subscription, you will not be charged for the next billing cycle. However, there are no prorated refunds for mid-cycle cancellations; you will have access until the current subscription period ends."
          ]
        },
        {
          "heading": "2.2 Involuntary Cancellations",
          "content": [
            "Ai Academic Writer reserves the right to suspend or terminate your subscription at any time due to violations of our Terms of Service or any other breach of agreement. In such cases, no refunds will be issued."
          ]
        }
      ]
    },
    {
      "heading": "3. Contact Us",
      "content": [
        "If you have any questions or need assistance with your refund or cancellation, please reach out to our customer support team at:",
        "- **Ai Academic Writer Support Team**",
        "- **Email**: speedapps1010@gmail.com",
        "- **Phone**: 8176851179",
        "- **Website**: [https://aiacademicswriter.com/]"
      ]
    },
    {
      "heading": "4. Changes to This Policy",
      "content": [
        "Ai Academic Writer reserves the right to modify this Refund & Cancellation Policy at any time. Any changes will be posted on our website and will take effect immediately. It is your responsibility to review this policy periodically."
      ]
    }
  ]
  };
