import React from 'react';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';  // Import Navigate here
import Main from './library/Main';  // Import your Main component
import PrivacyPolicy from './library/components/PrivacyPolicy';  // Privacy Policy Component
import TermsAndCondition from './library/components/TermsAndCondition';  // Terms and Conditions Component
import Refund from './library/components/Refundpolicy';  // Refund Policy Component

function App() {
  return (
      <Routes>
        <Route path="/" element={<Main />} />  {/* Default route to show Main page */}
        <Route path="/privacy" element={<PrivacyPolicy />} />  {/* Privacy Policy page */}
        <Route path="/terms&condition" element={<TermsAndCondition />} />  {/* Terms page */}
        <Route path="/refundpolicy" element={<Refund />} />  {/* Refund Policy page */}
        
        {/* Catch-all route for invalid routes */}
        <Route path="*" element={<Navigate to="/" replace />} />  {/* Redirect any invalid route to the home page */}
      </Routes>
   
  );
}

export default App;
